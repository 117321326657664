<template>
  <div>
<a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="米茉智能技术有限公司"
      sub-title="ESTEEM PRAGMATIC"
    >
      <template slot="extra">
        <a-menu  mode="horizontal" style="background-color: rgb(235, 237, 240)">
      <a-menu-item key="mail"> <a-icon type="mail" />首页 </a-menu-item>
      <a-sub-menu>
        <span slot="title" class="submenu-title-wrapper"
          ><a-icon type="setting" />产品</span
        >
        <a-menu-item-group title="Item 1">
          <a-menu-item key="setting:1">
            Option 1
          </a-menu-item>
          <a-menu-item key="setting:2">
            Option 2
          </a-menu-item>
        </a-menu-item-group>
        <a-menu-item-group title="Item 2">
          <a-menu-item key="setting:3">
            Option 3
          </a-menu-item>
          <a-menu-item key="setting:4">
            Option 4
          </a-menu-item>
        </a-menu-item-group>
      </a-sub-menu>
      <a-menu-item key="alipay">
        <a href="/" target="_blank" rel="noopener noreferrer"
          >关于我们</a
        >
      </a-menu-item>
    </a-menu>
      </template>
    </a-page-header>
  </div>
</template>

<script>

export default {
  data(){
    return {
      checked1: false,
      checked2: false,
      checked3: false,
    }
  },
  methods:{
    handleChange(checked){
      console.log(checked)
    }
  }

 //mimologo:"<a-avatar>USER</a-avatar>"
}
</script>


<style>
tr:last-child td {
  padding-bottom: 0;
}
.firsttag{
  padding-left: 0;
}
#components-page-header-demo-responsive .content {
  display: flex;
}
#components-page-header-demo-responsive .ant-statistic-content  {
  font-size: 20px;
  line-height: 28px;
}
.ant-page-header-heading-extra{
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 576px) {
  #components-page-header-demo-responsive .content {
    display: block;
  }

  #components-page-header-demo-responsive .main {
    width: 100%;
    margin-bottom: 12px;
  }

  #components-page-header-demo-responsive .extra {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
}

</style>
